<template>
  <div>
    <a-row :gutter="12" v-if="visible">
      <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('total')">
        <a-card hoverable>
          <a-statistic
            title="全部"
            :value=statisticsVO.totalNumber
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('warn')">
        <a-card hoverable>
          <a-statistic
            title="预警"
            :value=statisticsVO.warnNumber
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('fault')">
        <a-card hoverable>
          <a-statistic
            title="故障"
            :value=statisticsVO.faultNumber
            style="text-align: center"
          >
          </a-statistic>
        </a-card>
      </a-col>
      <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleStatistic('normal')">
        <a-card hoverable>
          <a-statistic
            title="正常"
            :value=statisticsVO.normalNumber
            style="text-align: center;"
          >
          </a-statistic>
        </a-card>
      </a-col>
    </a-row>
    <a-card :bordered="false" :style="{marginTop: cardMarginTop}">
      <a-spin :spinning="cardLoading" tip="加载中...">
        <a-tabs :tabBarGutter="5" type="line" @change="handleTags" defaultActiveKey :tabBarStyle="{marginBottom: '24px'}">
          <a-tab-pane v-for="item in tags" :key="item" :tab="item">
            <div class="fs-search-box">
              <div class="table-page-search-wrapper">
                <a-form layout="inline">
                  <a-form-item label="设备名称">
                    <a-input v-model="searchParams.name" placeholder="请输入设备名称" allowClear/>
                  </a-form-item>
                  <a-form-item label="所属部门">
                    <a-tree-select
                      allowClear
                      :treeData="organizationTree"
                      v-model="searchParams.departmentId"
                      placeholder="请选择部门"
                      @change="onSearch"
                    >
                    </a-tree-select>
                  </a-form-item>
                  <a-form-item label="显示方式">
                    <a-radio-group v-model="display">
                      <a-radio-button value="table">列表</a-radio-button>
                      <a-radio-button value="card">卡片</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                  <a-form-item class="f-r m-r-none">
                    <a-button type="primary" @click="onSearch" icon="search">查询</a-button>
                    <a-button class="m-l-6" @click="resetForm" icon="reload">重置</a-button>
                  </a-form-item>
                </a-form>
              </div>
            </div>
            <a-table
              v-if="display === 'table'"
              size="middle"
              :columns="columns"
              :dataSource="searchData"
              :pagination="pagination"
              @change="tableChange"
              :rowKey="(record) => record.pointId"
              bordered
              :customRow="click"
              @click.stop>
              <template slot="_index" slot-scope="text, record, index">
                {{ searchParams.number * searchParams.size + index + 1 }}
              </template>
              <!--污水厂-->
              <template slot="OUT_COD.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.OUT_COD ? record.OUT_COD.collectTime : ''">
                  {{ (record.OUT_COD ? record.OUT_COD.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="OUT_PH.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.OUT_PH ? record.OUT_PH.collectTime : ''">
                  {{ (record.OUT_PH ? record.OUT_PH.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="OUT_Total_NH.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.OUT_Total_NH ? record.OUT_Total_NH.collectTime : ''">
                  {{ (record.OUT_Total_NH ? record.OUT_Total_NH.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="OUT_Total_N.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.OUT_Total_N ? record.OUT_Total_N.collectTime : ''">
                  {{ (record.OUT_Total_N ? record.OUT_Total_N.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="OUT_Total_P.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.OUT_Total_P ? record.OUT_Total_P.collectTime : ''">
                  {{ (record.OUT_Total_P ? record.OUT_Total_P.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="OUT_FT.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.OUT_FT ? record.OUT_FT.collectTime : ''">
                  {{ (record.OUT_FT ? record.OUT_FT.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <!--集污池-->
              <template slot="EMPTY_HEIGHT.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.EMPTY_HEIGHT ? record.EMPTY_HEIGHT.collectTime : ''">
                  {{ (record.EMPTY_HEIGHT ? record.EMPTY_HEIGHT.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="BATTERY_VOLTAGE.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft"
                           :title="record.BATTERY_VOLTAGE ? record.BATTERY_VOLTAGE.collectTime : ''">
                  {{ (record.BATTERY_VOLTAGE ? record.BATTERY_VOLTAGE.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="SIGNAL_STRENGTH.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft"
                           :title="record.SIGNAL_STRENGTH ? record.SIGNAL_STRENGTH.collectTime : ''">
                  {{ (record.SIGNAL_STRENGTH ? record.SIGNAL_STRENGTH.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <!--三格化粪池-->
              <template slot="EMPTY_HEIGHT.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.EMPTY_HEIGHT ? record.EMPTY_HEIGHT.collectTime : ''">
                  {{ (record.EMPTY_HEIGHT ? record.EMPTY_HEIGHT.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="BATTERY_VOLTAGE.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft"
                           :title="record.BATTERY_VOLTAGE ? record.BATTERY_VOLTAGE.collectTime : ''">
                  {{ (record.BATTERY_VOLTAGE ? record.BATTERY_VOLTAGE.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="SIGNAL_STRENGTH.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft"
                           :title="record.SIGNAL_STRENGTH ? record.SIGNAL_STRENGTH.collectTime : ''">
                  {{ (record.SIGNAL_STRENGTH ? record.SIGNAL_STRENGTH.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <!--一体化泵站-->
              <template slot="YW_Present.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.YW_Present ? record.YW_Present.collectTime : ''">
                  {{ (record.YW_Present ? record.YW_Present.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="Pump1_Run.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.Pump1_Run ? record.Pump1_Run.collectTime : ''">
                  {{ (record.Pump1_Run ? record.Pump1_Run.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="Pump2_Run.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.Pump2_Run ? record.Pump2_Run.collectTime : ''">
                  {{ (record.Pump2_Run ? record.Pump2_Run.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <!--提升泵站-->
              <template slot="IN_LT.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.IN_LT ? record.IN_LT.collectTime : ''">
                  {{ (record.IN_LT ? record.IN_LT.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="OUT_LT.collectValue" slot-scope="text, record">
                <a-tooltip placement="topLeft" :title="record.OUT_LT ? record.OUT_LT.collectTime : ''">
                  {{ (record.OUT_LT ? record.OUT_LT.collectValue : '') | roundNumber | checkValue }}
                </a-tooltip>
              </template>
              <template slot="action" slot-scope="text, record">
                <a @click="handleMoreMetrics(record)" @click.stop>更多</a>
              </template>
            </a-table>
            <div v-if="display === 'card'">
              <div style="border: 1px solid #d9d9d9; padding: 16px; border-radius: 4px">
                <a-row :gutter="[16,16]" type="flex" justify="start" align="top">
                  <a-col :sm="24" :md="12" :lg="8" :xl="6" @click="handleMoreMetrics(item)" v-for="item in searchData" :key="item.pointId">
                    <a-card
                      hoverable
                      style="border-radius: 10px; border-width: 0"
                      :headStyle="{ borderColor: item.pointColor, borderStyle: 'solid', borderWidth: '1px', backgroundColor: item.pointColor, borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }"
                      :bodyStyle="{ color: 'black', borderColor: item.pointColor, borderStyle: 'solid', borderWidth: '1px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px' }">
                      <template slot="title">
                        {{item.pointName}}
                      </template>
                      <template v-if="activeTag === '污水处理厂'">
                        <a-row type="flex" style="text-align: center; color: black">
                          <a-col :span="8" :style="{color: item.OUT_COD ? item.OUT_COD.color: 'black'}">
                            COD
                            {{ item.OUT_COD | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_PH ? item.OUT_PH.color: 'black'}">
                            PH
                            {{ item.OUT_PH | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_Total_NH ? item.OUT_Total_NH.color: 'black'}">
                            NH3
                            {{ item.OUT_Total_NH | checkUnit }}
                          </a-col>
                        </a-row>
                        <a-row type="flex" style="text-align: center; color: black; font-size: 23px; padding-bottom: 15px">
                          <a-col :span="8" :style="{color: item.OUT_COD ? item.OUT_COD.color: '#999999'}">
                            {{ (item.OUT_COD ? item.OUT_COD.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_PH ? item.OUT_PH.color: '#999999'}">
                            {{ (item.OUT_PH ? item.OUT_PH.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_Total_NH ? item.OUT_Total_NH.color: '#999999'}">
                            {{ (item.OUT_Total_NH ? item.OUT_Total_NH.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                        </a-row>
                        <a-row type="flex" style="text-align: center; color: black">
                          <a-col :span="8" :style="{color: item.OUT_Total_N ? item.OUT_Total_N.color: 'black'}">
                            TN
                            {{ item.OUT_Total_N | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_Total_P ? item.OUT_Total_P.color: 'black'}">
                            TP
                            {{ item.OUT_Total_P | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_FT ? item.OUT_FT.color: 'black'}">
                            流量
                            {{ item.OUT_FT | checkUnit }}
                          </a-col>
                        </a-row>
                        <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                          <a-col :span="8" :style="{color: item.OUT_Total_N ? item.OUT_Total_N.color: '#999999'}">
                            {{ (item.OUT_Total_N ? item.OUT_Total_N.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_Total_P ? item.OUT_Total_P.color: '#999999'}">
                            {{ (item.OUT_Total_P ? item.OUT_Total_P.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.OUT_FT ? item.OUT_FT.color: '#999999'}">
                            {{ (item.OUT_FT ? item.OUT_FT.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                        </a-row>
                      </template>
                      <template v-else-if="activeTag === '集污池' || activeTag === '三格化粪池'">
                        <a-row type="flex" style="text-align: center; color: black">
                          <a-col :span="8" :style="{color: item.EMPTY_HEIGHT ? item.EMPTY_HEIGHT.color: 'black'}">
                            空高
                            {{ item.EMPTY_HEIGHT | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.BATTERY_VOLTAGE ? item.BATTERY_VOLTAGE.color: 'black'}">
                            电压
                            {{ item.BATTERY_VOLTAGE | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.SIGNAL_STRENGTH ? item.SIGNAL_STRENGTH.color: 'black'}">
                            信号强度
                            {{ item.SIGNAL_STRENGTH | checkUnit }}
                          </a-col>
                        </a-row>
                        <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                          <a-col :span="8" :style="{color: item.EMPTY_HEIGHT ? item.EMPTY_HEIGHT.color: '#999999'}">
                            {{ (item.EMPTY_HEIGHT ? item.EMPTY_HEIGHT.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.BATTERY_VOLTAGE ? item.BATTERY_VOLTAGE.color: '#999999'}">
                            {{ (item.BATTERY_VOLTAGE ? item.BATTERY_VOLTAGE.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.SIGNAL_STRENGTH ? item.SIGNAL_STRENGTH.color: '#999999'}">
                            {{ (item.SIGNAL_STRENGTH ? item.SIGNAL_STRENGTH.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                        </a-row>
                      </template>
                      <template v-else-if="activeTag === '一体化泵站'">
                        <a-row type="flex" style="text-align: center; color: black">
                          <a-col :span="8" :style="{color: item.YW_Present ? item.YW_Present.color: 'black'}">
                            液位
                            {{ item.YW_Present | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.Pump1_Run ? item.Pump1_Run.color: 'black'}">
                            1#泵运行信号
                            {{ item.Pump1_Run | checkUnit }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.Pump2_Run ? item.Pump2_Run.color: 'black'}">
                            2#泵运行信号
                            {{ item.Pump2_Run | checkUnit }}
                          </a-col>
                        </a-row>
                        <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                          <a-col :span="8" :style="{color: item.YW_Present ? item.YW_Present.color: '#999999'}">
                            {{ (item.YW_Present ? item.YW_Present.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.Pump1_Run ? item.Pump1_Run.color: '#999999'}">
                            {{ (item.Pump1_Run ? item.Pump1_Run.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="8" :style="{color: item.Pump2_Run ? item.Pump2_Run.color: '#999999'}">
                            {{ (item.Pump2_Run ? item.Pump2_Run.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                        </a-row>
                      </template>
                      <template v-else-if="activeTag === '提升泵站'">
                        <a-row type="flex" style="text-align: center; color: black">
                          <a-col :span="12" :style="{color: item.IN_LT ? item.IN_LT.color: 'black'}">
                            进口液位
                            {{ item.IN_LT | checkUnit }}
                          </a-col>
                          <a-col :span="12" :style="{color: item.OUT_LT ? item.OUT_LT.color: 'black'}">
                            出口液位
                            {{ item.OUT_LT | checkUnit }}
                          </a-col>
                        </a-row>
                        <a-row type="flex" style="text-align: center; color: black; font-size: 23px">
                          <a-col :span="12" :style="{color: item.IN_LT ? item.IN_LT.color: '#999999'}">
                            {{ (item.IN_LT ? item.IN_LT.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                          <a-col :span="12" :style="{color: item.OUT_LT ? item.OUT_LT.color: '#999999'}">
                            {{ (item.OUT_LT ? item.OUT_LT.collectValue : '') | roundNumber | checkValue }}
                          </a-col>
                        </a-row>
                      </template>
                    </a-card>
                  </a-col>
                </a-row>
              </div>
              <div style="float: right; margin: 16px 0">
                <a-pagination
                  size="small"
                  :current="pagination.current"
                  :pageSize="pagination.pageSize"
                  :total="pagination.total"
                  :showTotal="pagination.showTotal"
                  @change="onPageChange"
                  show-size-changer
                  @showSizeChange="onShowSizeChange"
                />
              </div>
            </div>
          </a-tab-pane>
          <div slot="tabBarExtraContent">
            <span>注：</span>
            <a-tag color="#FF0000">故障</a-tag>
            <a-tag color="#FFA500">预警</a-tag>
            <a-tag color="#800080">故障且预警</a-tag>
          </div>
        </a-tabs>
      </a-spin>
    </a-card>
    <a-modal
      :title="tempDeviceName"
      :maskClosable="false"
      v-model="moreMetricsModal"
      width="1050px"
      @cancel="handleMoreMetricsModalCancel"
    >
      <MetricsMonitoring ref="moreMetricsRef" :pointId="pointId"></MetricsMonitoring>
      <a-button slot="footer" @click="handleMoreMetricsModalCancel">取消</a-button>
    </a-modal>
  </div>
</template>

<script>
  import SERVICE_URLS from '@/api/service.url'
  import MetricsMonitoring from '../monitoringPoint/MetricsMonitoring'
  import { wsChangColumns, jwChiColumns, sGeColumns, ythBengColumns, tsBengColumns } from './common/common'

  export default {
    name: 'YList',
    components: { MetricsMonitoring },
    data () {
      return {
        columns: wsChangColumns(),
        // 选中的监测点标签
        activeTag: '',
        // 监测点标签
        tags: [],
        // ↓查询条件
        searchParams: {
          number: 0,
          size: '10',
          direction: 'DESC',
          order: 'lastUpdated',
          tag: '',
          name: '',
          fault: '',
          warn: '',
          departmentId: undefined
        },
        // ↓是否预警 & 是否故障 选择列表
        statusList: [
          { value: '', label: '全部' },
          { value: 'true', label: '是' },
          { value: 'false', label: '否' }
        ],
        // ↓search方法查询的结果
        searchData: [],
        // ↓分页参数
        pagination: {
          current: 1,
          total: 1,
          pageSize: 10,
          showSizeChanger: true,
          showTotal: (total, range) => `第 ${range[0]}-${range[1]} 条 共${total}记录`
        },
        // ↓整个卡片的加载状态
        cardLoading: false,
        // ↓更多信息的Modal显示
        moreMetricsModal: false,
        // ↓Modal标题
        tempDeviceName: '设备名称',
        organizationTree: [],
        pointId: '',
        statisticsVO: {
          totalNumber: 0,
          warnNumber: 0,
          faultNumber: 0,
          normalNumber: 0
        },
        display: 'table',
        cardData: [],
        // 是否显示统计卡片
        visible: true,
        // 边距
        cardMarginTop: '24px'
      }
    },
    mounted () {
      this.loadTags()
      this.getOrganizationTree()
    },
    methods: {
      // 获取部门
      getOrganizationTree () {
        this.$http(this, {
          url: SERVICE_URLS.organization.antTree,
          noTips: true,
          success: (data) => {
            this.organizationTree = data.body
          }
        })
      },
      // 加载监测点标签
      loadTags () {
        this.tags = []
        this.$http(this, {
          url: SERVICE_URLS.equipment2.monitoringPoint.find_tags_by_is_monitor,
          noTips: true,
          success: (data) => {
            this.tags = data.body
            if (this.tags && this.tags.length > 0) {
              this.searchParams.tag = this.tags[0]
              this.handleTags(this.tags[0])
            } else {
              this.$message.error('暂无数据！')
            }
          },
          error: () => {
            this.$message.error('加载设备标签失败!')
          }
        })
      },
      // 点击标签
      handleTags (item) {
        if (item === '污水处理厂') {
          this.columns = wsChangColumns()
        } else if (item === '集污池') {
          this.columns = jwChiColumns()
        } else if (item === '三格化粪池') {
          this.columns = sGeColumns()
        } else if (item === '一体化泵站') {
          this.columns = ythBengColumns()
        } else if (item === '提升泵站') {
          this.columns = tsBengColumns()
        }
        this.searchParams.tag = item
        this.activeTag = item
        this.resetForm()
      },
      search () {
        if (!this.cardLoading) {
          this.cardLoading = true
          this.$http(this, {
            url: SERVICE_URLS.equipment2.monitoringPoint.search_point_metrics_page,
            noTips: true,
            data: this.searchParams,
            success: (data) => {
              if (data.body) {
                this.searchData = data.body.content
                this.pagination.total = data.body.totalElements
                this.pagination.current = data.body.number + 1
                this.pagination.pageSize = data.body.size
                this.searchParams.size = data.body.size
                this.searchParams.number = data.body.number
              } else {
                this.searchData = []
              }
              this.cardLoading = false
            },
            error: () => {
              this.cardLoading = false
            }
          })
        }
      },
      statisticsByTag () {
        if (this.visible) {
          this.$http(this, {
            url: SERVICE_URLS.equipment2.monitoringPoint.statisticsByTag,
            noTips: true,
            data: this.searchParams,
            success: (data) => {
              this.statisticsVO = data.body
            },
            error: () => {
              this.$message.error('按标签统计监测点失败')
            }
          })
        }
      },
      onSearch () {
        this.statisticsByTag()
        this.search()
      },
      // ↓重置表单
      resetForm () {
        this.searchParams.number = 0
        this.searchParams.size = '10'
        this.searchParams.direction = ''
        this.searchParams.order = ''
        this.searchParams.name = ''
        this.searchParams.fault = ''
        this.searchParams.warn = ''
        this.searchParams.departmentId = undefined
        this.statisticsByTag()
        this.search()
      },
      /*表格页数发生变化*/
      tableChange (pagination) {
        this.searchParams.number = pagination.current - 1
        this.searchParams.size = pagination.pageSize
        this.search()
      },
      // ↓获取更多指标信息
      handleMoreMetrics (item) {
        this.tempDeviceName = item.pointName
        this.moreMetricsModal = true
        this.pointId = item.pointId
        this.$nextTick(() => {
          this.$refs.moreMetricsRef.getListGroupByTagByPointId(item.pointId)
        })
      },
      // 关闭更多指标弹窗
      handleMoreMetricsModalCancel () {
        this.tempDeviceName = '设备名称'
        this.moreMetricsModal = false
      },
      click (record, index) {
        return {
          on: {
            click: () => {
              this.handleMoreMetrics(record)
            }
          }
        }
      },
      handleStatistic (value) {
        if (value === 'total') {
          this.searchParams.warn = ''
          this.searchParams.fault = ''
        } else if (value === 'warn') {
          this.searchParams.warn = 'true'
          this.searchParams.fault = ''
        } else if (value === 'fault') {
          this.searchParams.warn = ''
          this.searchParams.fault = 'true'
        } else if (value === 'normal') {
          this.searchParams.warn = 'false'
          this.searchParams.fault = 'false'
        }
        this.search()
      },
      // 构建卡片数据
      buildCardData () {
        this.cardData = []
        const data = this.searchData
        data.forEach((item, index) => {
          if (index === 0 || index % 4 === 0) {
            let rowData = []
            rowData.push(item)
            if (index + 1 < data.length) {
              rowData.push(data[index + 1])
            }
            if (index + 2 < data.length) {
              rowData.push(data[index + 2])
            }
            if (index + 3 < data.length) {
              rowData.push(data[index + 3])
            }
            this.cardData.push(rowData)
          }
        })
      },
      onPageChange (page, pageSize) {
        this.pagination.pageSize = pageSize
        this.pagination.current = page
        this.tableChange(this.pagination)
      },
      onShowSizeChange (current, pageSize) {
        this.pagination.pageSize = pageSize
        this.pagination.current = current
        this.tableChange(this.pagination)
      }
    },
    filters: {
      roundNumber (value) {
        if (typeof value === 'number') {
          let result = null
          // ↓四舍五入的值
          result = Math.round(value * 100) / 100
          // 判断四舍五入的值是不是小数
          if (result - parseInt(result) > 0) {
            // 是小数,补零算法
            let s_x = result.toString()
            let pos_decimal = s_x.indexOf('.')
            if (pos_decimal < 0) {
              pos_decimal = s_x.length
              s_x += '.'
            }
            while (s_x.length <= pos_decimal + 2) {
              s_x += '0'
            }
            return s_x
          } else {
            // 是整数，直接返回
            return result
          }
        } else {
          return value
        }
      },
      checkValue (value) {
        if (value || value === 0) {
          return value
        } else {
          return '/'
        }
      },
      checkUnit (value) {
        if (value && value.unit) {
          return '(' + value.unit + ')'
        } else {
          return ''
        }
      }
    }
  }
</script>

<style scoped>
  .fs-table tr th,
  .fs-table tr td {
    border: 1px solid rgb(232, 232, 232);
  }

  .fs-table-cell {
    text-align: center;
    padding: 8px 8px;
  }

  .ant-table-thead > tr > th {
    background: #eee;
  }
</style>
