<template>
  <a-spin :spinning="spinning">
    <div v-if="groupByTag.length>0">
      <a-tabs :tabBarGutter="5" type="card" v-if="groupByTag.length>1">
        <a-tab-pane v-for="(item,index) in groupByTag" :key="index" :tab="item.tag?item.tag:'无'">
          <a-table
            :pagination="false"
            :columns="columns"
            bordered
            size="small"
            :data-source="item.realInfoDTOList"
            :rowKey="(record) => record.id"
            :customRow="click"
            @click.stop
          >
            <template slot="_index" slot-scope="text, record, index">
              {{ index+1 }}
            </template>
            <template slot="cnName" slot-scope="text, record">
              <a v-if="record.metricsType==='yc' || record.metricsType==='yt'" @click="onClick(record)" @click.stop>
                {{ record.cnName }}
              </a>
              <template v-else>{{ record.cnName }}</template>
            </template>
            <template slot="collectTime" slot-scope="text, record">
              {{ moment(record.collectTime ).format('YYYY-MM-DD HH:mm:ss') }}
            </template>
            <template slot="metricsType" slot-scope="text, record">
              <a-tag v-if="record.metricsType === 'yc'" color="#f50">遥测</a-tag>
              <a-tag v-if="record.metricsType === 'yx'" color="#2db7f5">遥信</a-tag>
              <a-tag v-if="record.metricsType === 'yt'" color="#87d068">遥调</a-tag>
              <a-tag v-if="record.metricsType === 'yk'" color="#108ee9">遥控</a-tag>
            </template>
          </a-table>
        </a-tab-pane>
        <div slot="tabBarExtraContent">
          <span>注：</span>
          <a-tag color="#FF0000">故障</a-tag>
          <a-tag color="#FFA500">预警</a-tag>
          <a-tag color="#800080">故障且预警</a-tag>
        </div>
      </a-tabs>
      <template v-else>
        <div style="width: 100%;text-align: right;margin-bottom: 10px">
          <span>注：</span>
          <a-tag color="#FF0000">故障</a-tag>
          <a-tag color="#FFA500">预警</a-tag>
          <a-tag color="#800080">故障且预警</a-tag>
        </div>
        <a-table
          :pagination="false"
          :columns="columns"
          bordered size="small"
          :data-source="groupByTag[0].realInfoDTOList"
          :rowKey="(record) => record.id"
          :customRow="click"
          @click.stop
        >
          <template slot="_index" slot-scope="text, record, index">
            {{ index+1 }}
          </template>
          <template slot="cnName" slot-scope="text, record">
            <a v-if="record.metricsType==='yc' || record.metricsType==='yt'" @click="onClick(record)" @click.stop>
              {{ record.cnName }}
            </a>
            <template v-else>{{ record.cnName }}</template>
          </template>
          <template slot="collectTime" slot-scope="text, record">
            {{ moment(record.collectTime ).format('YYYY-MM-DD hh:mm:ss') }}
          </template>
          <template slot="metricsType" slot-scope="text, record">
            <a-tag v-if="record.metricsType === 'yc'" color="#f50">遥测</a-tag>
            <a-tag v-if="record.metricsType === 'yx'" color="#2db7f5">遥信</a-tag>
            <a-tag v-if="record.metricsType === 'yt'" color="#87d068">遥调</a-tag>
            <a-tag v-if="record.metricsType === 'yk'" color="#108ee9">遥控</a-tag>
          </template>
        </a-table>
      </template>
    </div>
    <a-empty v-else/>
    <a-modal
      :title="title+'-历史数据'"
      :visible="visible"
      @cancel="handleCancel"
      :footer="null"
      :width="1200"
      :destroyOnClose="true"
    >
      <Detail ref="detailRef" :monitoringPointId="pointId" :unit="unit"></Detail>
    </a-modal>
  </a-spin>
</template>
<script>
  import SERVICE_URLS from '@/api/service.url'
  import Detail from './Detail'
  import moment from 'moment'

  export default {
    components: { Detail },
    props: {
      pointId: {}
    },
    data() {
      return {
        tabPaneList: [],
        columns: [
          {
            title: '序号',
            width: 80,
            align: 'center',
            scopedSlots: { customRender: '_index' }
          },
          {
            title: '描述',
            align: 'center',
            scopedSlots: { customRender: 'cnName' },
            width: 200
          },
          {
            title: '变量值',
            dataIndex: 'collectValue',
            customRender: (text, record) => {
              //es6写法
              return `${record.collectValue}  ${record.unit ? '(' + record.unit + ')' : ''}`
            },
            align: 'center',
            width: 100,
            customCell(record) {
              let color = ''
              if (record.fault) {
                color = 'red'
              }
              if (record.warn) {
                color = 'orange'
              }
              if (record.fault && record.warn) {
                color = 'purple'
              }
              return {
                style: {
                  'color': color
                }
              }
            }
          },
          {
            title: '变量名',
            dataIndex: 'enName',
            align: 'center',
            width: 150
          },
          {
            title: '指标范围',
            dataIndex: 'metricsRange',
            align: 'center',
            width: 80
          },
          {
            title: '采集时间',
            dataIndex: 'collectTime',
            align: 'center',
            scopedSlots: { customRender: 'collectTime' },
            width: 150
          },
          {
            title: '指标类型',
            dataIndex: 'metricsType',
            align: 'center',
            scopedSlots: { customRender: 'metricsType' },
            width: 100
          }
          // {
          //   title: '描述别名',
          //   dataIndex: 'cnNameAlias',
          //   align: 'center',
          //   width: 100
          // }
        ],
        groupByTag: [],
        data: [],
        visible: false,
        monitoringPointId: '',
        title: '',
        spinning: true,
        unit: '',
      }
    },
    watch: {
      pointId (val) {
        this.pointId = val
      }
    },
    created () {
      this.getListGroupByTag(this.pointId)
    },
    methods: {
      moment,
      getListGroupByTag (pointId) {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.metricsRealInfoApi.find_list_group_by_tag,
          noTips: true,
          data: { pointId: pointId },
          success: (data) => {
            this.groupByTag = data.body
            this.spinning = false
          }
        })
      },
      onClick (record) {
        this.visible = true
        this.title = record.cnName
        this.unit = record.unit
        this.$nextTick(() => {
          this.$refs.detailRef.realId = record.id
          this.$refs.detailRef.handleMetricsHistory()
        })
      },
      handleCancel () {
        this.visible = false
      },
      getListGroupByTagByPointId (pointId) {
        this.spinning = true
        this.$http(this, {
          url: SERVICE_URLS.equipment2.metricsRealInfoApi.find_list_group_by_tag,
          noTips: true,
          data: { pointId: pointId },
          success: (data) => {
            this.groupByTag = data.body
            this.spinning = false
          }
        })
      },
      click (record, index) {
        return {
          on: {
            click: () => {
              if (record.metricsType === 'yc' || record.metricsType === 'yt') {
                this.onClick(record)
              }
            }
          }
        }
      },
    }
  }
</script>
<style>

</style>