// 公用表头
const pubColumns = () => {
  return [
    {
      title: '序号',
      width: 70,
      align: 'center',
      scopedSlots: { customRender: '_index' }
    },
    {
      title: '设备名称',
      width: 150,
      align: 'center',
      dataIndex: 'pointName'
    },
    {
      title: '所属部门',
      width: 150,
      align: 'center',
      dataIndex: 'departmentName'
    }
  ]
}
// 污水厂表头
export const wsChangColumns = () => {
  const columns = pubColumns()
  columns.push(...[
    {
      title: 'COD(mg/L)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'OUT_COD.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.OUT_COD ? record.OUT_COD.color : '') ? record.OUT_COD.color : '#595959'
          }
        }
      }
    },
    {
      title: 'PH',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'OUT_PH.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.OUT_PH ? record.OUT_PH.color : '') ? record.OUT_PH.color : '#595959'
          }
        }
      }
    },
    {
      title: 'NH3(mg/L)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'OUT_Total_NH.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.OUT_Total_NH ? record.OUT_Total_NH.color : '') ? record.OUT_Total_NH.color : '#595959'
          }
        }
      }
    },
    {
      title: 'TN(mg/L)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'OUT_Total_N.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.OUT_Total_N ? record.OUT_Total_N.color : '') ? record.OUT_Total_N.color : '#595959'
          }
        }
      }
    },
    {
      title: 'TP(mg/L)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'OUT_Total_P.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.OUT_Total_P ? record.OUT_Total_P.color : '') ? record.OUT_Total_P.color : '#595959'
          }
        }
      }
    },
    {
      title: '流量(m3/h)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'OUT_FT.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.OUT_FT ? record.OUT_FT.color : '') ? record.OUT_FT.color : '#595959'
          }
        }
      }
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ])
  return columns
}

// 集污池表头
export const jwChiColumns = () => {
  const columns = pubColumns()
  columns.push(...[
    {
      title: '空高(m)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'EMPTY_HEIGHT.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.EMPTY_HEIGHT ? record.EMPTY_HEIGHT.color : '') ? record.EMPTY_HEIGHT.color : '#595959'
          }
        }
      }
    },
    {
      title: '电压(v)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'BATTERY_VOLTAGE.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.BATTERY_VOLTAGE ? record.BATTERY_VOLTAGE.color : '') ? record.BATTERY_VOLTAGE.color : '#595959'
          }
        }
      }
    },
    {
      title: '信号强度',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'SIGNAL_STRENGTH.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.SIGNAL_STRENGTH ? record.SIGNAL_STRENGTH.color : '') ? record.SIGNAL_STRENGTH.color : '#595959'
          }
        }
      }
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ])
  return columns
}

// 三格化粪池表头
export const sGeColumns = () => {
  const columns = pubColumns()
  columns.push(...[
    {
      title: '空高(m)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'EMPTY_HEIGHT.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.EMPTY_HEIGHT ? record.EMPTY_HEIGHT.color : '') ? record.EMPTY_HEIGHT.color : '#595959'
          }
        }
      }
    },
    {
      title: '电压(v)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'BATTERY_VOLTAGE.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.BATTERY_VOLTAGE ? record.BATTERY_VOLTAGE.color : '') ? record.BATTERY_VOLTAGE.color : '#595959'
          }
        }
      }
    },
    {
      title: '信号强度',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'SIGNAL_STRENGTH.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.SIGNAL_STRENGTH ? record.SIGNAL_STRENGTH.color : '') ? record.SIGNAL_STRENGTH.color : '#595959'
          }
        }
      }
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ])
  return columns
}

// 一体化泵站表头
export const ythBengColumns = () => {
  const columns = pubColumns()
  columns.push(...[
    {
      title: '液位(m)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'YW_Present.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.YW_Present ? record.YW_Present.color : '') ? record.YW_Present.color : '#595959'
          }
        }
      }
    },
    {
      title: '1#泵运行信号',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'Pump1_Run.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.Pump1_Run ? record.Pump1_Run.color : '') ? record.Pump1_Run.color : '#595959'
          }
        }
      }
    },
    {
      title: '2#泵运行信号',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'Pump2_Run.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.Pump2_Run ? record.Pump2_Run.color : '') ? record.Pump2_Run.color : '#595959'
          }
        }
      }
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ])
  return columns
}

// 提升泵站表头
export const tsBengColumns = () => {
  const columns = pubColumns()
  columns.push(...[
    {
      title: '进口液位(m)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'IN_LT.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.IN_LT ? record.IN_LT.color : '') ? record.IN_LT.color : '#595959'
          }
        }
      }
    },
    {
      title: '出口液位(m)',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'OUT_LT.collectValue' },
      customCell (record) {
        return {
          style: {
            'color': (record.OUT_LT ? record.OUT_LT.color : '') ? record.OUT_LT.color : '#595959'
          }
        }
      }
    },
    {
      title: '操作',
      width: 100,
      align: 'center',
      scopedSlots: { customRender: 'action' }
    }
  ])
  return columns
}
