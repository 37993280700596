<template>
  <page-layout class="fs-page">
    <div slot="headerContent">
      <h1 class="title">设备实时监测</h1>
    </div>
    <Table></Table>
  </page-layout>
</template>

<script>
  import PageLayout from '@/components/page/PageLayout'
  import Table from './Table'

  export default {
    name: 'YList',
    components: { PageLayout, Table },
    data () {
      return {}
    },
    methods: {}
  }
</script>

<style scoped>

</style>
