<template>
  <div id="Line02"></div>
</template>
<script>
  import G2 from '@antv/g2'

  export default {
    name: 'Line02',
    props: {
      data: Array,
      height: {
        type: Number,
        request: true
      },
      // ↓x轴Key值，默认'xKey'
      xName: {
        type: String,
        request: true
      },
      // ↓y轴Key值，默认'yKey'
      yName: {
        type: String,
        request: true
      },
      timeFormat: {
        type: String,
        default: 'YYYY-MM-DD HH:mm'
      },
      // y轴单位
      yUnit: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        // data: [
        //   {
        //     collectTime: '2020-07-29 12:15:45',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.73
        //   },
        //   {
        //     collectTime: '2020-07-29 11:32:50',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.39
        //   },
        //   {
        //     collectTime: '2020-07-29 11:32:18',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.39
        //   },
        //   {
        //     collectTime: '2020-07-29 11:31:53',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.39
        //   },
        //   {
        //     collectTime: '2020-07-29 11:31:18',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.41
        //   },
        //   {
        //     collectTime: '2020-07-29 11:30:57',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.42
        //   },
        //   {
        //     collectTime: '2020-07-29 11:30:14',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.44
        //   },
        //   {
        //     collectTime: '2020-07-29 11:29:48',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.51
        //   },
        //   {
        //     collectTime: '2020-07-29 11:29:23',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.62
        //   },
        //   {
        //     collectTime: '2020-07-29 11:28:57',
        //     cnName: '溶解氧仪显示',
        //     collectValue: 0.81
        //   }
        //   ]
      }
    },
    watch: {
      data (val) {
        this.data = val
        this.transformType()
        // console.log(this.data)
        this.chart.source(this.data, this.scale)
        this.chart.repaint()
      }
    },
    mounted () {
      this.initChart()
    },
    methods: {
      transformType () {
        this.data.forEach((e) => {
          e.collectValue = parseFloat(e.collectValue)
        })
      },
      initChart () {
        this.transformType()
        const chart = new G2.Chart({
          id: 'Line02',
          forceFit: true,
          height: 400,
          padding: [40, 40, 30, 70],
        })
        const defs = {
          collectTime: {
            type: 'time',
            nice: false,
            mask: 'YYYY-MM-DD HH:mm:ss'
            // tickInterval: 2 * 24 * 60 * 60 // 对于 linear 类型的数据，可以设置 tickInterval 参数来设定每个刻度之间的间距，time 类型的单位为微秒
          },
          collectValue: {
            tickCount: 5,
            alias: '实时值',
          }
        }
        chart.source(this.data, defs)
        // chart.point().position('collectTime*collectValue')
        chart.line().position('collectTime*collectValue').shape('smooth')
        chart.render()
        chart.guide().text({
          top: true, // 指定 giude 是否绘制在 canvas 最上层，默认为 false, 即绘制在最下层
          position: ['start', 'end'], // 文本的起始位置，值为原始数据值，支持 callback
          content: '单位：' + (this.yUnit ?  this.yUnit : ''), // 显示的文本内容
          style: {  // 文本的图形样式属性
            fill: 'rgba(0, 0, 0, 0.65)', // 文本颜色
            fontSize: '12', // 文本大小
          }, // 文本的图形样式属性
          offsetX: -70, // x 方向的偏移量
          offsetY: -25, // y 方向偏移量
        })
        this.chart = chart
        this.chart.render()
      }
    }
  }
</script>
<style>
</style>